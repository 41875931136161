import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  z-index: 1000;
  animation: ${slideUp} 0.5s ease-out;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #333;
  flex: 1;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  white-space: nowrap;
  
  ${props => props.primary ? `
    background: #FFD700;
    color: #000;
    
    &:hover {
      background: #FFE44D;
    }
  ` : `
    background: #f5f5f5;
    color: #333;
    
    &:hover {
      background: #e5e5e5;
    }
  `}
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PolicyLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SettingsButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: ${fadeIn} 0.3s ease-out;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    bottom: 90px;
    left: 20px;
  }
`;

const CookieIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 11.34 21.94 10.7 21.82 10.08C21.74 9.63 21.4 9.27 20.96 9.16C20.36 9 19.95 8.45 19.95 7.83C19.95 7.24 20.32 6.71 20.88 6.53C21.34 6.39 21.69 6.01 21.76 5.54C21.91 4.71 22 3.87 22 3C22 2.45 21.55 2 21 2C20.13 2 19.29 2.09 18.46 2.24C17.99 2.31 17.61 2.66 17.47 3.12C17.29 3.68 16.76 4.05 16.17 4.05C15.55 4.05 15 3.64 14.84 3.04C14.73 2.6 14.37 2.26 13.92 2.18C13.3 2.06 12.66 2 12 2Z" fill="#333"/>
    <circle cx="7" cy="8" r="1.5" fill="#333"/>
    <circle cx="15" cy="13" r="1.5" fill="#333"/>
    <circle cx="10" cy="15" r="1.5" fill="#333"/>
  </svg>
);

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const cookiePreference = localStorage.getItem('cookiePreference');
    if (!cookiePreference) {
      setShowBanner(true);
    } else {
      setShowSettings(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiePreference', 'accepted');
    setShowBanner(false);
    setShowSettings(true);
  };

  const handleReject = () => {
    localStorage.setItem('cookiePreference', 'rejected');
    setShowBanner(false);
    setShowSettings(true);
  };

  const handleReset = () => {
    localStorage.removeItem('cookiePreference');
    setShowBanner(true);
    setShowSettings(false);
  };

  if (!showBanner && !showSettings) return null;

  return (
    <>
      {showBanner && (
        <Banner>
          <Content>
            <Text>
              Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. 
              Lee nuestra <PolicyLink to="/politica-cookies">política de cookies</PolicyLink> para más información.
            </Text>
            <ButtonGroup>
              <Button primary onClick={handleAccept}>
                Aceptar cookies
              </Button>
              <Button onClick={handleReject}>
                Rechazar
              </Button>
            </ButtonGroup>
          </Content>
        </Banner>
      )}
      
      {showSettings && !showBanner && (
        <SettingsButton onClick={handleReset} title="Configuración de cookies">
          <CookieIcon />
        </SettingsButton>
      )}
    </>
  );
};

export default CookieBanner;
