import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// ... (keep all styled components the same)
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PageWrapper = styled.div`
  background-color: #000;
  color: #fff;
  min-height: 100vh;
`;

const HeroSection = styled.section`
  background-image: url('/images/lead-magnet-hero.jpg');
  background-size: cover;
  background-position: center;
  padding: 4rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #FFD700;
  margin-bottom: 1rem;
  animation: ${fadeIn} 1s ease-out;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: #FFD700;
  color: #000;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    background-color: #FFF;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    animation: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  text-align: left;
  margin-top: 0.5rem;
`;

const CheckboxInput = styled.input`
  margin-top: 0.3rem;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
`;

const PrivacyLink = styled(Link)`
  color: #FFD700;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const BenefitsSection = styled.section`
  background-color: #2F4F4F;
  padding: 4rem 2rem;
  text-align: center;
`;

const BenefitsTitle = styled.h2`
  font-size: 2rem;
  color: #FFD700;
  margin-bottom: 2rem;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitItem = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const BenefitIcon = styled.div`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 1rem;
`;

const TestimonialsSection = styled.section`
  background-color: #000;
  padding: 4rem 2rem;
  text-align: center;
`;

const TestimonialsTitle = styled.h2`
  font-size: 2rem;
  color: #FFD700;
  margin-bottom: 2rem;
`;

const Testimonial = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const FinalCTASection = styled.section`
  background-color: #2F4F4F;
  padding: 4rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FinalCTAContent = styled.div`
  max-width: 600px;
  width: 100%;
`;
const LeadMagnetPage = () => {
  const [formData, setFormData] = useState({
    top: {
      email: '',
      name: '',
      acceptedPrivacy: false
    },
    bottom: {
      email: '',
      name: '',
      acceptedPrivacy: false
    }
  });

  const handleSubmit = async (e, location) => {
    e.preventDefault();
    const currentForm = formData[location];
    
    if (!currentForm.acceptedPrivacy) {
      alert('Por favor, acepta la política de privacidad para continuar.');
      return;
    }
    
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: currentForm.email,
          name: currentForm.name
        }),
      });
      
      if (response.ok) {
        alert('¡Gracias por suscribirte! Revisa tu correo para obtener la guía gratuita.');
        // Reset form
        setFormData(prev => ({
          ...prev,
          [location]: {
            email: '',
            name: '',
            acceptedPrivacy: false
          }
        }));
      } else {
        throw new Error('Error en la respuesta del servidor');
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      alert('Hubo un error al procesar tu solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  const handleInputChange = (location, field, value) => {
    setFormData(prev => ({
      ...prev,
      [location]: {
        ...prev[location],
        [field]: value
      }
    }));
  };

  const renderForm = (location) => (
    <Form onSubmit={(e) => handleSubmit(e, location)}>
      <Input 
        type="text" 
        placeholder="Nombre" 
        value={formData[location].name} 
        onChange={(e) => handleInputChange(location, 'name', e.target.value)} 
        required 
      />
      <Input 
        type="email" 
        placeholder="Correo electrónico" 
        value={formData[location].email} 
        onChange={(e) => handleInputChange(location, 'email', e.target.value)} 
        required 
      />
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          id={`privacy-${location}`}
          checked={formData[location].acceptedPrivacy}
          onChange={(e) => handleInputChange(location, 'acceptedPrivacy', e.target.checked)}
        />
        <CheckboxLabel htmlFor={`privacy-${location}`}>
          He leído y acepto la <PrivacyLink to="/politica-privacidad">política de privacidad</PrivacyLink>
        </CheckboxLabel>
      </CheckboxContainer>
      <SubmitButton type="submit" disabled={!formData[location].acceptedPrivacy}>
        Descargar guía gratuita ahora
      </SubmitButton>
    </Form>
  );

  return (
    <PageWrapper>
      <HeroSection>
        <Title>Descubre cómo aumentar tu energía y mejorar tu movilidad en solo 7 días</Title>
        <Subtitle>Recibe nuestra guía gratuita y empieza hoy mismo a transformar tu vitalidad y bienestar con entrenamientos híbridos.</Subtitle>
        {renderForm('top')}
      </HeroSection>

      <BenefitsSection>
        <BenefitsTitle>¿Qué aprenderás con nuestra guía gratuita?</BenefitsTitle>
        <BenefitsGrid>
          <BenefitItem>
            <BenefitIcon>⚡</BenefitIcon>
            <h3>Aumenta tu energía diaria</h3>
            <p>Estrategias rápidas para sentirte más activo.</p>
          </BenefitItem>
          <BenefitItem>
            <BenefitIcon>🤸</BenefitIcon>
            <h3>Mejora tu movilidad</h3>
            <p>Movimientos simples para mejorar la flexibilidad y prevenir dolores.</p>
          </BenefitItem>
          <BenefitItem>
            <BenefitIcon>🧘</BenefitIcon>
            <h3>Menos estrés, más bienestar</h3>
            <p>Ejercicios para reducir el estrés y sentirte mejor en tu día a día.</p>
          </BenefitItem>
        </BenefitsGrid>
      </BenefitsSection>

      <TestimonialsSection>
        <TestimonialsTitle>Esto es lo que dicen nuestros clientes</TestimonialsTitle>
        <Testimonial>
          <p>"Después de seguir los consejos de la guía, sentí un aumento en mi energía en solo una semana. ¡Ahora hago ejercicios sin dolor!"</p>
          <p>- María G.</p>
        </Testimonial>
      </TestimonialsSection>

      <FinalCTASection>
        <FinalCTAContent>
          <Title>Empieza hoy mismo a mejorar tu salud y energía</Title>
          <Subtitle>No pierdas más tiempo. Recibe ahora nuestra guía gratuita y descubre cómo puedes aumentar tu vitalidad y energía en solo 7 días, con ejercicios que se adaptan a tu vida.</Subtitle>
          {renderForm('bottom')}
        </FinalCTAContent>
      </FinalCTASection>
    </PageWrapper>
  );
};

export default LeadMagnetPage;
