import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const CTASectionWrapper = styled.section`
  background-color: #2F4F4F;
  padding: 4rem 2rem;
  text-align: center;
`;

const CTATitle = styled.h2`
  color: #FFD700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const CTAText = styled.p`
  color: #FFF;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
`;

const Input = styled.input`
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
`;

const CTAButton = styled.button`
  display: inline-block;
  background-color: #FFD700;
  color: #000;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #FFF;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  text-align: left;
  margin-top: 0.5rem;
`;

const CheckboxInput = styled.input`
  margin-top: 0.3rem;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
  text-align: left;
`;

const PrivacyLink = styled(Link)`
  color: #FFD700;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const CTASection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!acceptedPrivacy) {
      alert('Por favor, acepta la política de privacidad para continuar.');
      return;
    }

    try {
      await axios.post('/api/subscribe', { name, email });
      alert('¡Gracias por suscribirte! Revisa tu correo para obtener la guía gratuita.');
      setName('');
      setEmail('');
      setAcceptedPrivacy(false);
    } catch (error) {
      console.error('Error al suscribirse:', error);
      alert('Hubo un error al procesar tu solicitud. Por favor, intenta de nuevo más tarde.');
    }
  };

  return (
    <CTASectionWrapper>
      <CTATitle>Empieza hoy mismo a mejorar tu salud y energía</CTATitle>
      <CTAText>
        No pierdas más tiempo. Recibe ahora nuestra guía gratuita y descubre cómo puedes aumentar tu vitalidad y energía en solo 7 días, con ejercicios que se adaptan a tu vida.
      </CTAText>
      <Form onSubmit={handleSubmit}>
        <Input 
          type="text" 
          placeholder="Nombre" 
          value={name} 
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input 
          type="email" 
          placeholder="Correo electrónico" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            id="privacy-cta"
            checked={acceptedPrivacy}
            onChange={(e) => setAcceptedPrivacy(e.target.checked)}
          />
          <CheckboxLabel htmlFor="privacy-cta">
            He leído y acepto la <PrivacyLink to="/politica-privacidad">política de privacidad</PrivacyLink>
          </CheckboxLabel>
        </CheckboxContainer>
        <CTAButton type="submit" disabled={!acceptedPrivacy}>
          Descargar guía gratuita ahora
        </CTAButton>
      </Form>
    </CTASectionWrapper>
  );
};

export default CTASection;
