import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const TestimonialsWrapper = styled.section`
  background-color: #000;
  padding: 4rem 2rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #FFD700;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const TestimonialCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  width: 300px;
  text-align: left;
  animation: ${fadeIn} 0.6s ease-out forwards;
  transition: transform 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ClientInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ClientImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
`;

const ClientName = styled.h3`
  color: #FFD700;
  font-size: 1.2rem;
  margin: 0;
`;

const TestimonialText = styled.p`
  color: #fff;
  font-size: 1rem;
  line-height: 1.6;
`;

const VideoIcon = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #FFD700;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Testimonials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const testimonials = [
    {
      name: "Emilio",
      image: "/testimonios/emilio.png",
      text: "Añadir texto",
      videoUrl: "/testimonios/Videoentrevista Emilio.mov"
    },
    {
      name: "María",
      image: "/testimonios/maria.png",
      text: "Añadir texto",
      videoUrl: "/testimonios/Videoentrevista María.mov"
    }
  ];

  const openModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideo('');
  };

  return (
    <TestimonialsWrapper>
      <SectionTitle>Esto es lo que dicen nuestros clientes</SectionTitle>
      <TestimonialsContainer>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <ClientInfo>
              <ClientImage src={testimonial.image} alt={testimonial.name} />
              <ClientName>{testimonial.name}</ClientName>
            </ClientInfo>
            <TestimonialText>{testimonial.text}</TestimonialText>
            <VideoIcon onClick={() => openModal(testimonial.videoUrl)}>
              <i className="fas fa-play-circle"></i>
            </VideoIcon>
          </TestimonialCard>
        ))}
      </TestimonialsContainer>
      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <video width="100%" controls>
              <source src={currentVideo} type="video/mp4" />
              Tu navegador no soporta el tag de video.
            </video>
          </ModalContent>
        </ModalOverlay>
      )}
    </TestimonialsWrapper>
  );
};

export default Testimonials;