import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #000;
  color: #FFF;
  padding: 3rem 2rem 2rem;
  overflow: hidden;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 250px;
  
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const FooterTitle = styled.h3`
  color: #FFD700;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
`;

const FooterLink = styled(Link)`
  color: #FFF;
  text-decoration: none;
  display: block;
  margin-bottom: 0.75rem;
  transition: color 0.3s ease;
  font-size: 0.95rem;

  &:hover {
    color: #FFD700;
  }
`;

const ExternalLink = styled.a`
  color: #FFF;
  text-decoration: none;
  display: block;
  margin-bottom: 0.75rem;
  transition: color 0.3s ease;
  font-size: 0.95rem;

  &:hover {
    color: #FFD700;
  }
`;

const ContactText = styled.p`
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
  color: #FFF;
`;

const SocialIcon = styled.a`
  color: #FFF;
  font-size: 1.8rem;
  transition: all 0.3s ease;

  &:hover {
    color: #FFD700;
    transform: translateY(-2px);
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #333;
  font-size: 0.9rem;
  color: #888;
`;

const LegalSection = styled(FooterSection)`
  @media (max-width: 768px) {
    order: -1;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Contacto</FooterTitle>
          <ExternalLink href="tel:+34645038262">+34 645 03 82 62</ExternalLink>
          <ExternalLink href="mailto:tiagofitpt@gmail.com">tiagofitpt@gmail.com</ExternalLink>
          <ContactText>Mallorca, España</ContactText>
        </FooterSection>
        
        <FooterSection>
          <FooterTitle>Síguenos</FooterTitle>
          <ContactText>Mantente al día con nuestras últimas novedades y consejos de entrenamiento</ContactText>
          <SocialIcon 
            href="https://www.instagram.com/tiagofitpt/" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Instagram"
          >
            <i className="fab fa-instagram"></i>
          </SocialIcon>
        </FooterSection>

        <LegalSection>
          <FooterTitle>Textos Legales</FooterTitle>
          <FooterLink to="/aviso-legal">Aviso Legal</FooterLink>
          <FooterLink to="/politica-privacidad">Política de Privacidad</FooterLink>
          <FooterLink to="/politica-cookies">Política de Cookies</FooterLink>
        </LegalSection>
      </FooterContent>

      <Copyright>
        <div>© {new Date().getFullYear()} Tiagofit. Todos los derechos reservados.</div>
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;
