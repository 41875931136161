import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f9fa, #fff);
  padding-top: 80px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
  
  h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h2 {
    font-size: 1.5rem;
    color: #666;
    font-weight: 400;
    
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

const Section = styled.section`
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 2.5rem;
  margin-bottom: 2rem;
  
  h3 {
    color: #1a1a1a;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  h4 {
    color: #333;
    font-size: 1.25rem;
    margin: 1.5rem 0 1rem;
    font-weight: 600;
  }
  
  p {
    color: #444;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 1rem;
      color: #444;
      line-height: 1.6;
      
      &:before {
        content: "•";
        color: #FFD700;
        font-weight: bold;
        position: absolute;
        left: 0;
      }
    }
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #0056b3;
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Container>
        <Header>
          <h1>POLÍTICA DE PRIVACIDAD</h1>
          <h2>www.tiagofitpt.com</h2>
        </Header>

        <Section>
          <h3>I. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h3>
          <p>Respetando lo establecido en la legislación vigente, Tiagofit (en adelante, también Sitio Web) se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos.</p>
          
          <h4>Leyes que incorpora esta política de privacidad</h4>
          <p>Esta política de privacidad está adaptada a la normativa española y europea vigente en materia de protección de datos personales en internet. En concreto, la misma respeta las siguientes normas:</p>
          <ul>
            <li>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).</li>
            <li>La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD).</li>
            <li>El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (RDLOPD).</li>
            <li>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE).</li>
          </ul>

          <h4>Identidad del responsable del tratamiento de los datos personales</h4>
          <p>El responsable del tratamiento de los datos personales recogidos en Tiagofit es: Miller Stiven Espinosa Muñoz, con NIF: DNI (en adelante, Responsable del tratamiento). Sus datos de contacto son los siguientes:</p>
          <ul>
            <li>Dirección: Mallorca</li>
            <li>Teléfono de contacto: Número</li>
            <li>Email de contacto: tiagofitpt@gmail.com</li>
          </ul>

          <h4>Registro de Datos de Carácter Personal</h4>
          <p>En cumplimiento de lo establecido en el RGPD y la LOPD-GDD, le informamos que los datos personales recabados por Tiagofit, mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestro fichero con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre Tiagofit y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este rellene, o para atender una solicitud o consulta del mismo.</p>

          <h4>Principios aplicables al tratamiento de los datos personales</h4>
          <p>El tratamiento de los datos personales del Usuario se someterá a los siguientes principios recogidos en el artículo 5 del RGPD y en el artículo 4 y siguientes de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales:</p>
          <ul>
            <li>Principio de licitud, lealtad y transparencia</li>
            <li>Principio de limitación de la finalidad</li>
            <li>Principio de minimización de datos</li>
            <li>Principio de exactitud</li>
            <li>Principio de limitación del plazo de conservación</li>
            <li>Principio de integridad y confidencialidad</li>
            <li>Principio de responsabilidad proactiva</li>
          </ul>

          <h4>Categorías de datos personales</h4>
          <p>Las categorías de datos que se tratan en Tiagofit son únicamente datos identificativos. En ningún caso, se tratan categorías especiales de datos personales en el sentido del artículo 9 del RGPD.</p>

          <h4>Base legal para el tratamiento de los datos personales</h4>
          <p>La base legal para el tratamiento de los datos personales es el consentimiento. Tiagofit se compromete a recabar el consentimiento expreso y verificable del Usuario para el tratamiento de sus datos personales para uno o varios fines específicos.</p>

          <h4>Períodos de retención de los datos personales</h4>
          <p>Los datos personales solo serán retenidos durante el tiempo mínimo necesario para los fines de su tratamiento y, en todo caso, únicamente durante el siguiente plazo: 18 meses, o hasta que el Usuario solicite su supresión.</p>

          <h4>Destinatarios de los datos personales</h4>
          <p>Los datos personales del Usuario serán compartidos con los siguientes destinatarios o categorías de destinatarios:</p>

          <h4>Datos personales de menores de edad</h4>
          <p>Respetando lo establecido en los artículos 8 del RGPD y 7 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, solo los mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por Tiagofit.</p>

          <h4>Secreto y seguridad de los datos personales</h4>
          <p>Tiagofit se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.</p>

          <h4>Derechos derivados del tratamiento de los datos personales</h4>
          <p>El Usuario tiene sobre Tiagofit y podrá, por tanto, ejercer frente al Responsable del tratamiento los siguientes derechos reconocidos en el RGPD y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales:</p>
          <ul>
            <li>Derecho de acceso</li>
            <li>Derecho de rectificación</li>
            <li>Derecho de supresión ("el derecho al olvido")</li>
            <li>Derecho a la limitación del tratamiento</li>
            <li>Derecho a la portabilidad de los datos</li>
            <li>Derecho de oposición</li>
          </ul>

          <h3>II. ACEPTACIÓN Y CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD</h3>
          <p>Es necesario que el Usuario haya leído y esté conforme con las condiciones sobre la protección de datos de carácter personal contenidas en esta Política de Privacidad, así como que acepte el tratamiento de sus datos personales para que el Responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del Sitio Web implicará la aceptación de la Política de Privacidad del mismo.</p>
        </Section>
      </Container>
    </PageWrapper>
  );
};

export default PrivacyPolicy;
