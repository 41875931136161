import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const grow = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`;

const BenefitsSectionWrapper = styled.section`
  background-color: #2F4F4F; // Dark slate gray
  padding: 4rem 2rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #FFD700;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
`;

const BenefitItem = styled.div`
  flex-basis: calc(33.333% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
  animation: ${fadeInUp} 0.6s ease-out forwards;
  animation-delay: ${props => props.delay}s;
  opacity: 0;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    flex-basis: calc(50% - 2rem);
  }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
`;

const BenefitIcon = styled.div`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;

  &:hover {
    animation: ${grow} 0.3s ease-in-out alternate infinite;
  }
`;

const BenefitTitle = styled.h3`
  color: #FFD700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const BenefitDescription = styled.p`
  color: #fff;
  font-size: 1rem;
`;

const BenefitsSection = () => {
  const benefits = [
    {
      icon: '⚡',
      title: 'Aumenta tu energía diaria',
      description: 'Estrategias rápidas para sentirte más activo y vital en tu día a día.'
    },
    {
      icon: '🤸',
      title: 'Mejora tu movilidad',
      description: 'Movimientos simples para mejorar la flexibilidad y prevenir dolores.'
    },
    {
      icon: '🧘',
      title: 'Menos estrés, más bienestar',
      description: 'Ejercicios para reducir el estrés y sentirte mejor en tu día a día.'
    }
  ];

  return (
    <BenefitsSectionWrapper>
      <SectionTitle>¿Qué aprenderás con nuestra guía gratuita?</SectionTitle>
      <BenefitsContainer>
        {benefits.map((benefit, index) => (
          <BenefitItem key={index} delay={0.2 * index}>
            <BenefitIcon>{benefit.icon}</BenefitIcon>
            <BenefitTitle>{benefit.title}</BenefitTitle>
            <BenefitDescription>{benefit.description}</BenefitDescription>
          </BenefitItem>
        ))}
      </BenefitsContainer>
    </BenefitsSectionWrapper>
  );
};

export default BenefitsSection;