import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
`;

const HeroWrapper = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/hero/hero.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(3px);
    pointer-events: none;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  animation: ${fadeIn} 1s ease-out;
  position: relative;
  z-index: 2;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const Button = styled.a`
  background-color: ${props => props.primary ? '#FFD700' : 'transparent'};
  color: ${props => props.primary ? '#000' : '#FFD700'};
  border: 2px solid #FFD700;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.primary ? '#FFF' : '#FFD700'};
    color: #000;
  }
`;

const ScrollDownIcon = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #FFD700;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
  z-index: 2;
`;

const HeroSection = () => {
  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <HeroWrapper>
      <HeroContent>
        <Title>Recupera tu vitalidad y energía con entrenamientos híbridos</Title>
        <Subtitle>Entrenamientos personalizados para mejorar tu salud y movilidad</Subtitle>
        <ButtonWrapper>
          <Button href="#" primary>Reserva tu primera sesión</Button>
          <Button href="#">Descubre nuestros programas</Button>
        </ButtonWrapper>
      </HeroContent>
      <ScrollDownIcon onClick={scrollDown}>
        <i className="fas fa-chevron-down"></i>
      </ScrollDownIcon>
    </HeroWrapper>
  );
};

export default HeroSection;
