import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const VideoSectionWrapper = styled.section`
  background-color: #000;
  padding: 4rem 2rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #FFD700;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto 2rem;
  position: relative;
  animation: ${fadeIn} 1s ease-out;
`;

const VideoPlaceholder = styled.div`
  background-color: #2F4F4F;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
`;

const PlayButton = styled.button`
  background-color: rgba(255, 215, 0, 0.8);
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    background-color: rgba(255, 215, 0, 1);
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #000;
    margin-left: 7px;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #FFD700;
  color: #000;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #FFF;
  }
`;

const VideoSection = () => {
  return (
    <VideoSectionWrapper>
      {/* <SectionTitle>Descubre nuestro método</SectionTitle>
      <VideoContainer>
        <VideoPlaceholder>
          <PlayButton aria-label="Reproducir video" />
        </VideoPlaceholder>
      </VideoContainer> */}
      <CTAButton href="#">Reserva tu sesión gratis</CTAButton>
    </VideoSectionWrapper>
  );
};

export default VideoSection;