import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
`;

const ButtonWrapper = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  z-index: 998;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: scale(1.1);
    color: #fff;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #25D366;
    border-radius: 50%;
    animation: ${pulse} 2s infinite;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    font-size: 25px;

    &:before {
      width: 50px;
      height: 50px;
    }
  }
`;

const Icon = styled.i`
  transform: translateY(2px); // Center the icon better
`;

const WhatsAppButton = () => {
  const phoneNumber = '34600000000'; // Update with the correct number
  const message = 'Hola, me gustaría obtener más información sobre los entrenamientos personalizados.';

  return (
    <ButtonWrapper 
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Contactar por WhatsApp"
    >
      <Icon className="fab fa-whatsapp" />
    </ButtonWrapper>
  );
};

export default WhatsAppButton;
