import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f9fa, #fff);
  padding-top: 80px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
  
  h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h2 {
    font-size: 1.5rem;
    color: #666;
    font-weight: 400;
    
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

const Section = styled.section`
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 2.5rem;
  margin-bottom: 2rem;
  
  h3 {
    color: #1a1a1a;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  h4 {
    color: #333;
    font-size: 1.25rem;
    margin: 1.5rem 0 1rem;
    font-weight: 600;
  }
  
  p {
    color: #444;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 1rem;
      color: #444;
      line-height: 1.6;
      
      &:before {
        content: "•";
        color: #FFD700;
        font-weight: bold;
        position: absolute;
        left: 0;
      }
    }
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #0056b3;
    }
  }
`;

const CookiePolicy = () => {
  return (
    <PageWrapper>
      <Container>
        <Header>
          <h1>POLÍTICA DE COOKIES</h1>
          <h2>www.tiagofitpt.com</h2>
        </Header>

        <Section>
          <p>El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que el servidor recuerde cierta información que posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.</p>

          <p>Las cookies son procedimientos automáticos de recogida de información relativa a las preferencias determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo como Usuario, y personalizar su experiencia y el uso del Sitio Web, y pueden también, por ejemplo, ayudar a identificar y resolver errores.</p>

          <h4>Cookies propias</h4>
          <p>Son aquellas cookies que son enviadas al ordenador o dispositivo del Usuario y gestionadas exclusivamente por Tiagofit para el mejor funcionamiento del Sitio Web. La información que se recaba se emplea para mejorar la calidad del Sitio Web y su Contenido y su experiencia como Usuario. Estas cookies permiten reconocer al Usuario como visitante recurrente del Sitio Web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias.</p>

          <h4>Deshabilitar, rechazar y eliminar cookies</h4>
          <p>El Usuario puede deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas en su dispositivo mediante la configuración de su navegador. En este sentido, los procedimientos para rechazar y eliminar las cookies pueden diferir de un navegador de Internet a otro. En consecuencia, el Usuario debe acudir a las instrucciones facilitadas por el propio navegador de Internet que esté utilizando.</p>

          <p>En el supuesto de que rechace el uso de cookies —total o parcialmente— podrá seguir usando el Sitio Web, si bien podrá tener limitada la utilización de algunas de las prestaciones del mismo.</p>
        </Section>
      </Container>
    </PageWrapper>
  );
};

export default CookiePolicy;
