import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f9fa, #fff);
  padding-top: 80px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
  
  h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  
  h2 {
    font-size: 1.5rem;
    color: #666;
    font-weight: 400;
    
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

const Section = styled.section`
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 2.5rem;
  margin-bottom: 2rem;
  
  h3 {
    color: #1a1a1a;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  h4 {
    color: #333;
    font-size: 1.25rem;
    margin: 1.5rem 0 1rem;
    font-weight: 600;
  }
  
  p {
    color: #444;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 1rem;
      color: #444;
      line-height: 1.6;
      
      &:before {
        content: "•";
        color: #FFD700;
        font-weight: bold;
        position: absolute;
        left: 0;
      }
    }
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #0056b3;
    }
  }
`;

const LegalNotice = () => {
  return (
    <PageWrapper>
      <Container>
        <Header>
          <h1>AVISO LEGAL Y CONDICIONES GENERALES DE USO</h1>
          <h2>www.tiagofitpt.com</h2>
        </Header>

        <Section>
          <h3>I. INFORMACIÓN GENERAL</h3>
          <p>En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:</p>
          
          <p>La titularidad de este sitio web, www.tiagofitpt.com, (en adelante, Sitio Web) la ostenta: Miller Espinosa, con NIF: DNI, y cuyos datos de contacto son:</p>
          <ul>
            <li>Dirección: MALLORCA</li>
            <li>Teléfono de contacto: NÚMERO</li>
            <li>Email de contacto: miller.stiven.cursos@gmail.com</li>
          </ul>

          <h3>II. TÉRMINOS Y CONDICIONES GENERALES DE USO</h3>
          <h4>El objeto de las condiciones: El Sitio Web</h4>
          <p>El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante, Servicios).</p>

          <p>Tiagofit se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento Tiagofit pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.</p>

          <h4>El Usuario</h4>
          <p>El acceso, la navegación y uso del Sitio Web confiere la condición de Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso.</p>

          <h3>III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB</h3>
          <p>Tiagofit no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios. Tiagofit hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.</p>

          <h3>IV. POLÍTICA DE ENLACES</h3>
          <p>El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web de Tiagofit deberá saber que:</p>
          <p>No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorización expresa de Tiagofit.</p>

          <h3>V. PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
          <p>Tiagofit por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en el mismo.</p>

          <h3>VI. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h3>
          <p>Tiagofit se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.</p>
        </Section>
      </Container>
    </PageWrapper>
  );
};

export default LegalNotice;
