import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import BenefitsSection from './components/BenefitsSection';
import Testimonials from './components/Testimonials';
import VideoSection from './components/VideoSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';
import LeadMagnetPage from './components/LeadMagnetPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';
import LegalNotice from './components/LegalNotice';
import CookieBanner from './components/CookieBanner';
import WhatsAppButton from './components/WhatsAppButton';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    overflow-x: hidden;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    background-color: #000;
    color: #fff;
    position: relative;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px; /* Accounts for fixed header */
  }

  /* Modern scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Override background color for legal pages */
  body.legal-page {
    background-color: #f8f9fa;
  }

  /* Smooth transition for theme changes */
  body {
    transition: background-color 0.3s ease;
  }

  /* Fix for mobile browsers address bar */
  @media screen and (max-width: 768px) {
    body {
      min-height: -webkit-fill-available;
    }
    html {
      height: -webkit-fill-available;
    }
  }
`;

const MainPage = () => (
  <>
    <Header />
    <main>
      <section id="inicio">
        <HeroSection />
      </section>
      <section id="beneficios">
        <BenefitsSection />
      </section>
      <section id="testimonios">
        <Testimonials />
      </section>
      <section id="programas">
        <VideoSection />
      </section>
      <section id="contacto">
        <CTASection />
      </section>
    </main>
    <Footer />
  </>
);

// Component to handle legal pages layout
const LegalPageLayout = ({ children }) => {
  React.useEffect(() => {
    document.body.classList.add('legal-page');
    return () => {
      document.body.classList.remove('legal-page');
    };
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

function App() {
  return (
    <Router>
      <GlobalStyle />
      <CookieBanner />
      <WhatsAppButton />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/lead-magnet" element={<LeadMagnetPage />} />
        <Route 
          path="/politica-privacidad" 
          element={
            <LegalPageLayout>
              <PrivacyPolicy />
            </LegalPageLayout>
          } 
        />
        <Route 
          path="/politica-cookies" 
          element={
            <LegalPageLayout>
              <CookiePolicy />
            </LegalPageLayout>
          } 
        />
        <Route 
          path="/aviso-legal" 
          element={
            <LegalPageLayout>
              <LegalNotice />
            </LegalPageLayout>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
